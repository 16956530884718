import React from 'react'
import { Helmet } from 'react-helmet'

import './../assets/css/mentions-legales.css'

const MentionsLegales = () => {
  return (
    <>
      <Helmet>
        <title>Mentions Légales | Paramedic</title>
      </Helmet>
      <div className='container content'>
        <h1>Mentions Légales</h1>
        <h3><span>1. Présentation du site.</span></h3>
        <p><span>En raison de l&rsquo;article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans
          l&rsquo;économie numérique, il est précisé aux utilisateurs du site <a
            href='http://www.paramedic.tech/'
                                                                              >www.paramedic.tech
                                                                              </a> l&rsquo;identité des différents
          intervenants dans le cadre de sa réalisation et de son suivi :
           </span>
        </p>
        <p><span><strong>Paramedic.</strong> est propriété de Paramedic. SAS au capital de 100 000 € dont le siège est
          situé 15 allées de l&rsquo;Europe 92110 Clichy et immatriculée au RCS de Paris sous le numéro :
          830 635 272. <strong><br />
                       </strong><strong>Responsable de publication</strong> : André Delaforge –
          andre@paramedic.tech
           </span><br />
          <span>Le responsable de publication est une personne physique ou une personne morale.</span><br />
          <span><strong>Développeur</strong> : Return Digital – www.return.digital</span><br />
          <span><strong>Hébergeur</strong> : OVH – 2 rue Kellermann &#8211; 59100 Roubaix</span>
        </p>
        <h3><span>2. Conditions générales d’utilisation du site et des services proposés.</span></h3>
        <p><span>L’utilisation du site <a href='http://www.paramedic.tech/'>www.paramedic.tech</a> implique
          l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites. Ces
          conditions d’utilisation sont susceptibles d’être modifiées ou complétées à tout moment, les
          utilisateurs du site <a href='http://www.paramedic.tech/'>www.paramedic.tech</a> sont donc
          invités à les consulter de manière régulière.
           </span>
        </p>
        <p><span>Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de
          maintenance technique peut être toutefois décidée par Paramedic., qui s’efforcera alors de
          communiquer préalablement aux utilisateurs les dates et heures de l’intervention.
           </span>
        </p>
        <p><span>Le site <a href='http://www.paramedic.tech/'>www.paramedic.tech</a> est mis à jour régulièrement par
          Return Digital. De la même façon, les mentions légales peuvent être modifiées à tout moment :
          elles s’imposent néanmoins à l’utilisateur qui est invité à s’y référer le plus souvent possible
          afin d’en prendre connaissance.
           </span>
        </p>
        <h3><span>3. Description des services fournis.</span></h3>
        <p><span>Le site <a href='http://www.paramedic.tech/'>www.paramedic.tech</a> a pour objet de fournir une
          information concernant l’ensemble des activités de Paramedic.
           </span>
        </p>
        <p><span>Paramedic. s’efforce de fournir sur le site <a href='http://www.paramedic.tech/'>www.paramedic.tech</a>
          des informations aussi précises que possible. Toutefois, il ne pourra pas être tenu responsable
          des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son
          fait ou du fait des tiers partenaires qui lui fournissent ces informations.
           </span>
        </p>
        <p><span>Toutes les informations indiquées sur le site <a
          href='http://www.paramedic.tech/'
                                                               >www.paramedic.tech
        </a> sont données à titre indicatif,
          et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurants sur le site <a
            href='http://www.paramedic.tech/'
                                                                                                 >www.paramedic.tech
                                                                                                 </a> ne sont pas exhaustifs. Ils
          sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
           </span>
        </p>
        <h3><span>4. Limitations contractuelles sur les données techniques.</span></h3>
        <p><span>Le site utilise la technologie JavaScript.</span></p>
        <p><span>Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De
          plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne
          contenant pas de virus et avec un navigateur de dernière génération mis-à-jour
           </span>
        </p>
        <h3><span>5. Propriété intellectuelle et contrefaçons.</span></h3>
        <p><span>Paramedic. est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur
          tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo,
          icônes, sons, logiciels.
           </span>
        </p>
        <p><span>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des
          éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation
          écrite préalable de : Paramedic..
           </span>
        </p>
        <p><span>Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera
          considérée comme constitutive d’une contrefaçon.
           </span>
        </p>
        <h3><span>6. Limitations de responsabilité.</span></h3>
        <p><span>Paramedic. ne pourra être tenu responsable des dommages directs et indirects causés au matériel de
          l’utilisateur, lors de l’accès au site www.paramedic.tech, et résultant soit de l’utilisation
          d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition
          d’un bug ou d’une incompatibilité.
           </span>
        </p>
        <p><span>Paramedic. ne pourra également être tenu responsable des dommages indirects (tels que : par exemple,
          une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site <a
            href='http://www.paramedic.tech/'
                                                                                         >www.paramedic.tech
                                                                                         </a> ou à toute autre application
          Web ou Mobile Paramedic.
           </span>
        </p>
        <p><span>Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont à la
          disposition des utilisateurs. Paramedic. se réserve le droit de supprimer, sans mise en demeure
          préalable, tout contenu déposé dans cet espace, en particulier aux dispositions relatives à la protection des données. Le cas
          échéant, Paramedic. se réserve également la possibilité de mettre en cause la responsabilité
          de l’utilisateur, notamment en cas de message à caractère raciste,
          injurieux, diffamant, ou pornographique, quel que soit le support utilisé (texte,
          photographie…).
           </span>
        </p>
        <h3><span>7. Gestion des données personnelles.</span></h3>
        <p><span>En raison de l&rsquo;entrée en vigueur du règlement général sur la protection des données depuis le 25
          Mai 2018, nous vous invitons à consulter la page Politique de Confidentialité Paramedic.
          accessible depuis le lien ci après : <a
            href='http://paramedic.tech/confidentialite.html/'
                                               >http://paramedic.tech/confidentialite.html
                                               </a>
        </span>
        </p>
        <p><span>A l&rsquo;occasion de l&rsquo;utilisation du site <a
          href='http://www.paramedic.tech/'
                                                                   >www.paramedic.tech
        </a>, peuvent êtres recueillies :
          l&rsquo;URL des liens par l&rsquo;intermédiaire desquels l&rsquo;utilisateur a accédé au site <a
            href='http://www.paramedic.tech/'
                                                                                                        >www.paramedic.tech
                                                                                                        </a>, le fournisseur d&rsquo;accès
          de l&rsquo;utilisateur, l&rsquo;adresse de protocole Internet (IP) de
          l&rsquo;utilisateur.
           </span>
        </p>
        <p><span>En tout état de cause Paramedic. ne collecte des informations personnelles relatives à
          l&rsquo;utilisateur que pour le besoin de certains services proposés par le site <a
            href='http://www.paramedic.tech/'
                                                                                           >www.paramedic.tech
                                                                                           </a>. L&rsquo;utilisateur fournit
          ces informations en toute connaissance de cause, notamment lorsqu&rsquo;il procède par lui-même
          à leur saisie. Il est alors précisé à l&rsquo;utilisateur du site <a
            href='http://www.paramedic.tech/'
                                                                            >www.paramedic.tech
                                                                            </a> l’obligation ou non de fournir
          ces informations.
           </span>
        </p>
        <p><span>Aucune information personnelle de l&rsquo;utilisateur du site <a
          href='http://www.paramedic.tech/'
                                                                               >www.paramedic.tech
        </a> n&rsquo;est publiée à
          l&rsquo;insu de l&rsquo;utilisateur, échangée, transférée, cédée ou vendue sur un support
          quelconque à des tiers. Seule l&rsquo;hypothèse du rachat de Paramedic. et de ses droits
          permettrait la transmission des dites informations à l&rsquo;éventuel acquéreur qui serait à son
          tour tenu de la même obligation de conservation et de modification des données vis à vis de
          l&rsquo;utilisateur du site <a href='http://www.paramedic.tech/'>www.paramedic.tech</a>.
        </span>
        </p>
        <h3><span>8. Liens hypertextes et cookies.</span></h3>
        <p><span>Le site <a href='http://www.paramedic.tech/'>www.paramedic.tech</a> contient un certain nombre de liens
          hypertextes vers d’autres sites, mis en place avec l’autorisation de Paramedic.. Cependant,
          Paramedic. n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera
          en conséquence aucune responsabilité de ce fait.
           </span>
        </p>
        <p><span>La navigation sur le site <a href='http://www.paramedic.tech/'>www.paramedic.tech</a> est susceptible
          de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un
          fichier de petite taille, qui ne permet pas l’identification de l’utilisateur, mais qui
          enregistre des informations relatives à la navigation d’un ordinateur sur un site. Les données
          ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également
          vocation à permettre diverses mesures de fréquentation.
           </span>
        </p>
        <p><span>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services.
          L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser
          l’installation des cookies :
           </span>
        </p>
        <p><span>Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en haut a droite) / options
          internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur
          Ok.
           </span>
        </p>
        <p><span>Sous Firefox : en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis aller dans
          l&rsquo;onglet Options. Cliquer sur l&rsquo;onglet Vie privée.
           </span><br />
          <span>Paramétrez les Règles de conservation sur : utiliser les paramètres personnalisés pour
            l&rsquo;historique. Enfin décochez-la pour désactiver les cookies.
          </span>
        </p>
        <p><span>Sous Safari : Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par un
          rouage). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section «
          Confidentialité », cliquez sur Paramètres de contenu. Dans la section « Cookies », vous pouvez
          bloquer les cookies.
           </span>
        </p>
        <p><span>Sous Chrome : Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par trois
          lignes horizontales). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans
          la section « Confidentialité », cliquez sur préférences. Dans l&rsquo;onglet « Confidentialité
          », vous pouvez bloquer les cookies.
           </span>
        </p>
        <h3><span>9. Lexique.</span></h3>
        <p><span>Utilisateur : Internaute se connectant, utilisant le site susnommé.</span></p>
        <p><span>Informations personnelles : « les informations qui permettent, sous quelque forme que ce soit,
          directement ou non, l&rsquo;identification des personnes physiques auxquelles elles
          s&rsquo;appliquent ».
           </span>
        </p>
      </div>
    </>
  )
}

export default MentionsLegales
